import React, { useState } from 'react'
import leftMenu from '../../Images/leftMenu.png'
import next from '../../Images/next.png'
import closeMenu from '../../Images/closeMenu.png'
import Logo from '../../Images/Logo.png'
import locationpin from '../../Images/locationpin.png'
import compliant from '../../Images/compliant.png'
import Review from '../../Images/Review.png'
import telephone from '../../Images/telephone.png'
import book from '../../Images/book.png'
import verified from '../../Images/verified.png'
import './Header.scss';
import $ from 'jquery';
import { useLocation } from "react-router-dom";

import appmoney from '../../Images/appmoney.png'
import appcalendar from '../../Images/appcalendar.png'
import appsearch from '../../Images/appsearch.png'
import appdropshipping from '../../Images/appdropshipping.png'
import appwallet from '../../Images/appwallet.png'
import Close from '../../Images/closeMenu.png'
import Arrow from '../../Images/downArrow.png'

export default function Header({ showMenu, setShowMenu, navigateTo, WebSitePages }) {
    const [OpenFirstMenu, setOpenFirstMenu] = useState(false);
    const [header, setHeader] = useState(false)
    const [header2, setHeader2] = useState(false)
    const [expleation, setExpleation] = useState(false);

    // const { pathname } = useLocation();
    // if (pathname === "/order") return null;
    return (
        <div className="Header">

            <div className="HeaderTopComputer">
                <div className="Header2" onClick={() => navigateTo("")}>
                    <img src={locationpin} alt="" />
                    <h1><b>Riverside, CA Heating & Air Conditioning</b></h1>
                </div>

                <div className="Header1">
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            <a href="/">Home</a>
                        </div>
                    </div>
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            <h1>Services</h1>
                            <img src={Arrow} alt="" />
                        </div>

                        <div className="Header1BoxMenu">
                            <a className="Header1BoxMenuBox" href="/service/Air-Conditioning-Repair">Air Conditioning Repair</a>
                            <a className="Header1BoxMenuBox" href="/service/Ac-Services">Ac Services</a>
                            <a className="Header1BoxMenuBox" href="/service/Hvac-Repair-Near-Me">Hvac Repair Near Me</a>
                            <a className="Header1BoxMenuBox" href="/service/Ac-Repair">Ac Repair</a>
                            <a className="Header1BoxMenuBox" href="/service/Ac-Installation">Ac Installation</a>
                            <a className="Header1BoxMenuBox" href="/service/Hvac-Services">Hvac Services</a>
                            <a className="Header1BoxMenuBox" href="/service/Air-Conditioning-Service">Air Conditioning Service</a>
                            <a className="Header1BoxMenuBox" href="/service/Furnace-Repair">Furnace Repair</a>
                            <a className="Header1BoxMenuBox" href="/service/Heating-And-Cooling">Heating And Cooling</a>
                            <a className="Header1BoxMenuBox" href="/service/Ac-Maintenance">Ac Maintenance</a>
                            <a className="Header1BoxMenuBox" href="/service/Emergency-Hvac-Service">Emergency Hvac Service</a>
                            <a className="Header1BoxMenuBox" href="/service/Duct-Cleaning">Duct Cleaning</a>
                            <a className="Header1BoxMenuBox" href="/service/Indoor-Air-Quality">Indoor Air Quality</a>
                            <a className="Header1BoxMenuBox" href="/service/Hvac-Tune-up">Hvac Tune-up</a>
                            <a className="Header1BoxMenuBox" href="/service/Heat-Pump-Repair">Heat Pump Repair</a>
                            <a className="Header1BoxMenuBox" href="/service/Hvac-Contractors">Hvac Contractors</a>
                            <a className="Header1BoxMenuBox" href="/service/Air-Conditioning-Installation">Air Conditioning Installation</a>
                            <a className="Header1BoxMenuBox" href="/service/Heating-System-Repair">Heating System Repair</a>
                            <a className="Header1BoxMenuBox" href="/service/Central-Air-Conditioning">Central Air Conditioning</a>
                            <a className="Header1BoxMenuBox" href="/service/Residential-Hvac">Residential Hvac</a>
                            <a className="Header1BoxMenuBox" href="/service/Commercial-Hvac-Services">Commercial Hvac Services</a>
                            <a className="Header1BoxMenuBox" href="/service/Thermostat-Installation">Thermostat Installation</a>
                            <a className="Header1BoxMenuBox" href="/service/Energy-efficient-Hvac">Energy-efficient Hvac</a>
                            <a className="Header1BoxMenuBox" href="/service/Ventilation-Services">Ventilation Services</a>
                            <a className="Header1BoxMenuBox" href="/service/Hvac-Installation">Hvac Installation</a>
                            <a className="Header1BoxMenuBox" href="/service/Hvac-Repair">Hvac Repair</a>
                            <a className="Header1BoxMenuBox" href="/service/Ac-Repair-Near-Me">Ac Repair Near Me</a>
                            <a className="Header1BoxMenuBox" href="/service/Condenser-Repair">Condenser Repair</a>
                            <a className="Header1BoxMenuBox" href="/service/Compressor-Repair">Compressor Repair</a>
                            <a className="Header1BoxMenuBox" href="/service/Evaporatoer-Repair">Evaporatoer Repair</a>
                            <a className="Header1BoxMenuBox" href="/service/Cooling-System-Repair">Cooling System Repair</a>
                            <a className="Header1BoxMenuBox" href="/service/Air-Duct-Cleaning">Air Duct Cleaning</a>
                            <a className="Header1BoxMenuBox" href="/service/Dryer-Vent-Cleaning">Dryer Vent Cleaning</a>
                        </div>
                    </div>
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            <a href="/contact">Contact Us</a>
                        </div>
                    </div>
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            <a href="/blog">Blog</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="HeaderTop">
                <div className="Header2" onClick={() => navigateTo("")}>
                    <img src={locationpin} alt="" />
                    <h1><b>Riverside, CA Heating & Air Conditioning </b></h1>
                </div>
                <div className='Header3'>
                    {showMenu ?
                        <img
                            style={header ? { "filter": "invert(1)", "width": "30px" } : { "filter": "invert(0)", "width": "30px" }}
                            src={closeMenu} alt=""
                            className='menuCloseBtn'
                            onClick={() => setShowMenu(false)}
                        />
                        :
                        <img
                            style={header ? { "filter": "invert(1)" } : { "filter": "invert(0)" }}
                            src={leftMenu} alt=""
                            onClick={() => setShowMenu(true)}
                        />
                    }

                </div>
            </div>


            <div className="HeaderBottom">
                <div className='Header2'>
                    <div className="Header2Box" onClick={() => navigateTo("contact")}>
                        <img src={compliant} alt="" />
                        <h2>Contact Us</h2>
                    </div>
                    <div className="Header2Box" onClick={() => navigateTo("blog")}>
                        <img src={Review} alt="" />
                        <h2 >Blog</h2>
                    </div>
                </div>
                <div className="Header1Main">
                    <div className='Header1'>
                        <a href="/book">BOOK AN APPOINTMENT<img src={book} alt="" /></a>
                    </div>
                    <div className='Header1'>
                        <a href="tel:951-724-3389">CALL 951-724-3389<img src={telephone} alt="" /></a>
                    </div>
                </div>
            </div>

            {/* Applications Toturial  */}
            {expleation ?
                <div className="ApplicationsToturial">
                    <div className="ApplicationsToturialShadow" onClick={() => setExpleation(false)}></div>
                    <div className="ApplicationsToturialMain">
                        <div className="ApplicationsToturialMainImgClose">
                            <img src={Close} alt="" onClick={() => setExpleation(false)} />
                        </div>
                        <div className="ApplicationsToturialMainHeader">
                            <h3>You Are Secure</h3>
                            <h4>We prioritize your financial security and protect you with encryption, identity verification, and the most widely secure payment processor.</h4>
                        </div>
                        <div className="ApplicationsToturialMainBody">
                            <div className="ApplicationsToturialMainBodyBox">
                                <div className="ApplicationsToturialMainBodyBoxLeft">
                                    <img src={appsearch} alt="" />
                                </div>
                                <div className="ApplicationsToturialMainBodyBoxRight">
                                    <h3>Soft Credit Check</h3>
                                    <h4>
                                        A soft inquiry allows Riverside Heating & Air Conditioning  to review your credit to get a sense of how much fund your business will qualified for.
                                    </h4>
                                    <h5>This will NOT affect your credit score.</h5>
                                </div>
                            </div>
                            <div className="ApplicationsToturialMainBodyBox">
                                <div className="ApplicationsToturialMainBodyBoxLeft">
                                    <img src={appmoney} alt="" />
                                </div>
                                <div className="ApplicationsToturialMainBodyBoxRight">
                                    <h3>Your Data, Encrypted</h3>
                                    <h4>We protect your personal and financial data with bank level encryption.</h4>
                                </div>
                            </div>
                            <div className="ApplicationsToturialMainBodyBox">
                                <div className="ApplicationsToturialMainBodyBoxLeft">
                                    <img src={appwallet} alt="" />
                                </div>
                                <div className="ApplicationsToturialMainBodyBoxRight">
                                    <h3>3 Minute Application</h3>
                                    <h4>Our application form is easy to fill out and can be completed in less than 3 minutes!</h4>
                                </div>
                            </div>
                            <div className="ApplicationsToturialMainBodyBox">
                                <div className="ApplicationsToturialMainBodyBoxLeft">
                                    <img src={appcalendar} alt="" />
                                </div>
                                <div className="ApplicationsToturialMainBodyBoxRight">
                                    <h3>Get Fund Today!</h3>
                                    <h4>Once you've accepted the offer, we'll promptly send you a contract that outlines the terms and conditions of the agreement.</h4>
                                </div>
                            </div>
                        </div>
                        <div className="ApplicationsToturialMainBodyClose" onClick={() => setExpleation(false)}>
                            <h1>Dismiss</h1>
                        </div>
                    </div>
                </div>
                : null}
        </div>
    )
}

import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Footer.scss';
import facebook from '../../Images/facebook.png'
import instagram from '../../Images/instagram.png'
import linkedin from '../../Images/linkedin.png'
import twitter from '../../Images/twitter.png'
import threads from '../../Images/threads.png'
import Logo from '../../Images/Logo.png'

// import { useLocation } from "react-router-dom";

export default function Footer({ WebSitePages, navigateTo }) {
    const Navigate = useNavigate()
    return (
        <div className="Footer">
            <div className="Footer2">
                <div className="Footer2Logo" onClick={() => navigateTo("")}>
                    <h1><b>Riverside</b> Heating & Air Conditioning</h1>
                </div>
            </div>
            <div className="FooterTop">

                <div className="SiteMapDiv">
                    <h1>Riverside Heating & Air Conditioning</h1>
                    <div className="SiteMapDivList">
                        <a href="/">Home Page</a>
                        <a href="/book">Book Heating & Air Conditioning Appointment Online In Riverside, California</a>
                        <a href="/contact">Contact Us</a>
                        <a href="/blog">Blog</a>
                        <a href="/faq">Faq</a>
                        <a href="/sitemap">Site Map</a>
                    </div>
                </div>
                <div className="SiteMapDiv">
                    <h1>Services</h1>
                    <div className="SiteMapDivList">
                        <a className="Header1BoxMenuBox" href="/service/Air-Conditioning-Repair">Air Conditioning Repair In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Ac-Services">Ac Services In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Hvac-Repair-Near-Me">Hvac Repair Near Me In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Ac-Repair">Ac Repair In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Ac-Installation">Ac Installation In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Hvac-Services">Hvac Services In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Air-Conditioning-Service">Air Conditioning Service In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Furnace-Repair">Furnace Repair In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Heating-And-Cooling">Heating And Cooling In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Ac-Maintenance">Ac Maintenance In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Emergency-Hvac-Service">Emergency Hvac Service In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Duct-Cleaning">Duct Cleaning In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Indoor-Air-Quality">Indoor Air Quality In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Hvac-Tune-up">Hvac Tune-up In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Heat-Pump-Repair">Heat Pump Repair In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Hvac-Contractors">Hvac Contractors In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Air-Conditioning-Installation">Air Conditioning Installation In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Heating-System-Repair">Heating System Repair In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Central-Air-Conditioning">Central Air Conditioning In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Residential-Hvac">Residential Hvac In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Commercial-Hvac-Services">Commercial Hvac Services In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Thermostat-Installation">Thermostat Installation In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Energy-efficient-Hvac">Energy-efficient Hvac In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Ventilation-Services">Ventilation Services In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Hvac-Installation">Hvac Installation In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Hvac-Repair">Hvac Repair In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Ac-Repair-Near-Me">Ac Repair Near Me In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Condenser-Repair">Condenser Repair In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Compressor-Repair">Compressor Repair In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Evaporatoer-Repair">Evaporatoer Repair In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Cooling-System-Repair">Cooling System Repair In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Air-Duct-Cleaning">Air Duct Cleaning In Riverside, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Dryer-Vent-Cleaning">Dryer Vent Cleaning In Riverside, CA</a>
                    </div>
                </div>
                <div className="SiteMapDiv">
                    <h1>Contact Us</h1>
                    <div className="SiteMapDivList">
                        <a target='_blank' href="">
                            Riverside, California
                        </a>
                        <a target='_blank' href="tel:951-724-3389">
                            951-724-3389
                        </a>
                        <a target='_blank' href="mailto:info@riversidehvacairconditioning.org">
                            info@riversidehvacairconditioning.org
                        </a>
                    </div>
                </div>

            </div>
            <div className="Footer3">
                <h1>Copyright © 2023 Riverside Heating & Air Conditioning. All rights reserved.</h1>
            </div>
        </div>
    )
}
